<template>
	<div class="container">
		<div class="row cc title">{{ bindMode ? '绑定新手机号码' : '欢迎登录杏田问答' }}</div>
		<div class="row cb form-item">
			<div class="row cc label">
				<span>+86</span>
				<van-icon name="arrow-down" size="small" />
			</div>
			<div class="input-view"><input type="tel" placeholder="请输入手机号" v-model="mobile" oninput="if(value.length>11)value=value.slice(0,11)" /></div>
		</div>
		<van-button :disabled="mobile.length === 0" class="btn" type="primary" round block @click="navCaptcha()">获取验证码</van-button>
		<div class="row cc fixed-bottom" v-if="bindMode">
			<div class="text-rule">
				<span>旧手机号无法使用？请</span>
				<span class="green" @click="openService()">联系客服</span>
			</div>
		</div>
		<div class="row cc fixed-bottom" v-else>
			<van-checkbox icon-size="16px" checked-color="#03d3bc" v-model="checked">
				<div class="text-rule">
					<span>请先阅读并同意</span>
					<span class="green" @click.stop="navAgreement()">服务协议</span>
					<span>和</span>
					<span class="green" @click.stop="navPolicy()">隐私政策</span>
				</div>
			</van-checkbox>
		</div>
		<van-popup v-model="showService" :style="{ width: '244px', borderRadius: '8px' }">
			<div class="col cc popup-main">
				<img class="qr" :src="$store.getters.oss(setting.customer_image)" />
				<span>扫码添加小助手更换绑定</span>
			</div>
		</van-popup>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
			showService: false,
			checked: false,
			mobile: '',
			setting: {}
		};
	},
	computed: {
		// 是否是换绑定手机号模式
		bindMode() {
			return this.$route.name == 'Bind';
		}
	},
	created() {
		this.mpLogin().then(async res => {
			let { data } = await this.$service.post('auth/setShow');
			this.setting = data;
		});
	},
	methods: {
		navAgreement() {
			this.$router.push({
				path: '/agreement'
			});
		},
		navPolicy() {
			this.$router.push({
				path: '/policy'
			});
		},
		navCaptcha() {
			const reg = /1[3456789]{1}\d{9}$/;
			if (reg.test(this.mobile)) {
				if (this.bindMode) {
					this.$router.replace({
						path: '/captcha',
						query: {
							bind: true,
							mobile: this.mobile
						}
					});
				} else {
					if (this.checked) {
						this.$router.replace({
							path: '/captcha',
							query: {
								mobile: this.mobile
							}
						});
					} else {
						this.$toast('请先阅读并同意服务协议和隐私政策');
					}
				}
			} else {
				this.$toast('请输入正确的手机号');
			}
		},
		openService() {
			this.showService = true;
		}
	}
};
</script>

<style scoped lang="less">
@import './index.less';
</style>
